import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyFormFileUpload = lazy(
  () => import("./form-file-upload-C-CnoB3z.js").then((module) => ({
    default: module.FormFileUpload
  }))
);
function FormFileUpload({
  uuid: _uuid,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyFormFileUpload, { ...props });
}
const useFormFileUploadContent = () => {
  return useMemo(
    () => ({
      formFileUpload: {
        component: FormFileUpload
      }
    }),
    []
  );
};
export {
  LazyFormFileUpload as FormFileUpload,
  useFormFileUploadContent
};
